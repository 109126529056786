<template>
  <div class="home">
    <div class="navbox paddingBox" :class="styleBool ? 'changenav' : ''">
      <div class="navcontent align-center">
        <img src="../assets/logo.png" alt="" class="logoimg" />
        <div class="flex1 align-center">
          <ul v-for="(v, i) in navList" :key="i" class="navul align-center">
            <li
              class="navulLi"
              @click="jump(i)"
              :class="activeindex == i ? 'changenavulLi' : ''"
            >
              {{ v }}
            </li>
          </ul>
          <div class="navulLi2" @click="dialogVisible = true">APP下载</div>
        </div>

        <div class="navbtnBox">
          <el-button class="org-Btn" type="primary">
            <a href="/g" class="atext" style="text-decoration:none">社群登录</a>
          </el-button>
          <el-button  @click="dialogVisible = true" class="color orgbtn"
            >企业登录</el-button>
          <!-- <el-button type="primary" class="busBtn" @click="dialogVisible = true"
            >APP下载</el-button
          > -->
        </div>
        <el-dialog
          :visible.sync="dialogVisible"
          :append-to-body="true"
          :before-close="handleClose"
          center
        >
          <div class="imgbox listcode">
            <img src="../assets/qrcode.png" alt="" class="imgloading" />
            <div class="codepng">扫码下载商屋APP，完成企业登录</div>
          </div>
          
          <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" class="color orgbtn"
              >取 消</el-button
            >
            <el-button
              type="primary"
              class="busBtn"
              @click="dialogVisible = false"
              >确 定</el-button
            >
          </span> -->
        </el-dialog>
      </div>
    </div>

    <div @scroll="scrollEvent">
      <div class="img1Box align-center paddingBox2">
        <div class="textBox">
          <p class="p1">构建企业级社群新生态</p>
          <p class="p2">搭建企业私域，实现全链路数字化商业闭环</p>
          <div class="navbtnBox">
            <router-link to="/contact">
              <el-button type="primary" class="busBtn">入驻商屋</el-button>
            </router-link>
          </div>
        </div>
        <img src="../assets/banner.png" alt="" class="banner" />
      </div>

      <div class="img2Box d_jump">
        <div class="item item1 space-between">
          <img src="../assets/img1.png" alt="" class="img1" />
          <div class="textBox">
            <p class="p1">全方位的产业资讯</p>
            <p class="p2">来自社群内外企业的海量行业信息共享</p>
          </div>
        </div>
        <div class="item item2 space-between">
          <div class="textBox">
            <p class="p1">可信赖的社交模式</p>
            <p class="p2">社群背书的企业高信任度价值社交</p>
          </div>
          <img src="../assets/img2.png" alt="" class="img2" />
        </div>
        <div class="item item3 space-between">
          <img src="../assets/img3.png" alt="" class="img3" />
          <div class="textBox">
            <p class="p1">多渠道的资源拓展</p>
            <p class="p2">私域、公域、社群间合作等多种方式为企业聚集资源</p>
          </div>
        </div>
        <div class="item item4 space-between">
          <div class="textBox">
            <p class="p1">最精准的营销推广</p>
            <p class="p2">基于大数据推荐算法，私域内外多渠道精准投放</p>
          </div>
          <img src="../assets/img4.png" alt="" class="img4" />
        </div>
      </div>

      <div class="img3Box d_jump">
        <div class="title">全面的数据安全保障</div>
        <ul class="space-between">
          <li>
            <img src="../assets/pink.png" alt="" class="pink" />
            <div class="text">
              只有获得授权，数据才允许被访问，数据始终流转在安全范围之内
            </div>
          </li>
          <li>
            <img src="../assets/green.png" alt="" class="pink" />
            <div class="text">
              采用独立部署，数据隔离边界清晰，防止数据泄露，互不干扰
            </div>
          </li>
          <li>
            <img src="../assets/blue.png" alt="" class="pink" />
            <div class="text">
              分级管理数据，精细管控不同身份的全生命周期数据，保证数据安全
            </div>
          </li>
          <li>
            <img src="../assets/yel.png" alt="" class="pink" />
            <div class="text">
              用户身份认证，端到端加密传输，防止网络攻击，保障链路安全
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="img4Box d_jump">
        <div class="title">服务100+社群机构，为10000+企业打通连接</div>
        <ul class="itembox">
          <li v-for="(item, index) in userList" :key="index">
            <div class="imgbox">
              <img :src="item.img" alt="" class="pink" />
            </div>
            <div class="text">{{ item.name }}</div>
          </li>
        </ul>
      </div> -->

      <div class="img5Box center flex-col">
        <div class="title">开启私域增长之路，发掘市场无限商机</div>
        <div class="navbtnBox">
          <router-link to="/contact">
            <el-button type="primary" class="busBtn">入驻商屋</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      navList: ["产品服务", "数据安全",  "加入商屋"],
      userList: [
        {
          img: require(`../assets/home1.png`),
          name: "西安人力资源服务行业协会",
        },
        {
          img: require(`../assets/home2.png`),
          name: "西安市装饰业协会设计专业委员会",
        },
        { img: require(`../assets/home3.png`), name: "西安市老字号产业促进会" },
        {
          img: require(`../assets/home4.png`),
          name: "西安市汽车产业链供应链联盟",
        },
        {
          img: require(`../assets/home5.png`),
          name: "西安市平凉商会",
        },
      ],
      styleBool: false,
      titleOnIndex: "",
      catalogCur: "",
      activeindex: -1,
      dialogVisible: false,
    };
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
    },
    handleScroll() {
      let top = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      );
      if (top > 0) {
        this.styleBool = true;
      } else {
        this.styleBool = false;
      }
    },
    /* 监听滚动(结合前面的@scroll) */
    scrollEvent(e) {
      let scrollItems = document.querySelectorAll(".d_jump");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.catalogCur = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.catalogCur = this.articleName.length - 1;
      }
      // '下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
    },
    /* 目录点击定位效果：有兼容问题，借鉴了很多其他方法却一直不成功，无奈之后使用了scrollIntoView */
    jump(index) {
      this.activeindex = index;
      if (index == 2) {
        this.$router.push({ name: "Contact" });
        return;
      }
      this.titleOnIndex = index;
      let jump = document.querySelectorAll(".d_jump");
      jump[index].scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
 ::v-deep .el-dialog{
    width: 320px;
    height: 320px;
  }
  ::v-deep .el-dialog__body{
    height: 67%;
    display: flex;
    align-items: center;
  }
.imgbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgloading {
  width: 145px;
  height: 130px;
}
.atext {
  text-decoration: none !important;
  color: #fff !important;
}
.changenav {
  background: #fff !important;
}

.navbox {
  position:sticky ;
  top: 0;
  left: 0;
  background-color: #fcf7f1;

  .navcontent {
    min-width: 7.5rem;
    max-width: 14.3rem;
    height: 72px;

    .logoimg {
      width: 51px;
      height: 44px;
      margin-right: 0.58rem;
    }
    .navulLi2{
      font-size: 16px;
        margin-right: 0.4rem;
        cursor: pointer;
    }
    .navulLi2:hover{
      color: #7356c5;
    }
    .navul {
      .navulLi {
        font-size: 16px;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .changenavulLi {
        color: #7356c5;
        font-weight: bold;
      }

      .navulLi:hover {
        color: #7356c5;
      }
    }

    .navbtnBox {
      .el-button {
        border-radius: 0.04rem;
        width: 96px;
        height: 38px;
        padding: 0;
        font-size: 16px;
        border-color: #7356c5;
      }

      .orgbtn {
        background-color: transparent;
        font-weight: 400;
      }

      .orgbtn:hover {
        border: 2px solid #7356c5;
        font-weight: 500;
      }
    }
  }
}
.busBtn {
  font-weight: 400;
}
.busBtn:hover {
  background-color: #5c459e;
  font-weight: 500;
}
.org-Btn {
  font-weight: 400;
}
.org-Btn:hover {
  background-color: #5c459e;
  font-weight: bold;
}

.img1Box {
  background-color: #fcf7f1;
  padding-top: 1.38rem;
  padding-bottom: 0.9rem;

  .banner {
    width: 5.84rem;
    height: 4.44rem;
  }

  .textBox {
    margin-right: 0.75rem;

    .p1 {
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 0.16rem;
      white-space: nowrap;
      display: -moz-inline-box;
      // min-width: 5.4rem;
    }

    .p2 {
      font-size: 20px;
      margin-bottom: 0.41rem;
      white-space: normal;
      display: -moz-inline-box;
    }

    .navbtnBox {
      .el-button {
        font-size: 18px;
        padding: 0.13rem 0.34rem;
      }
    }
  }
}

.img2Box {
  padding-bottom: 0.59rem;
  padding-top: 1.36rem;

  .item {
    .img1 {
      width: 7.87rem;
      // height: 6.57rem ;
      min-width: 600px;
      // min-height: 500px;
    }

    .img2 {
      width: 8.01rem;
      // height: 7.87rem;
    }

    .img3 {
      width: 7.62rem;
      // height: 8.66rem;
    }

    .img4 {
      width: 7.84rem;
      // height: 7.79rem;
    }

    .textBox {
      .p1 {
        font-size: 44px;
        margin-bottom: 0.2rem;
        font-weight: bold;
        white-space: nowrap;
      }

      .p2 {
        font-size: 20px;
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  .item1 {
    padding: 0 3.4rem 0 2.88rem;
  }

  .item2 {
    padding: 0 2.7rem 0 3.59rem;
  }

  .item3 {
    padding: 0 3rem 0 2.91rem;

    .p1 {
      text-align: right;
    }
  }

  .item4 {
    padding: 0 2.8rem 0 3.6rem;
  }
}

.img3Box {
  background-color: #fcf7f1;
  padding: 1.02rem 3.8rem 0.75rem 3.6rem;

  .title {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.88rem;
  }

  ul {
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.56rem;

      .pink {
        width: 0.96rem;
        height: 0.96rem;
        margin-right: 0.2rem;
      }

      .text {
        width: 3.6rem;
        font-size: 18px;
        color: #000000;
      }
    }
  }
}

.img4Box {
  padding: 1.28rem 3.5rem 0.8rem 3.5rem;
  .title {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.99rem;
    white-space: nowrap;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 20%;
      min-height: 190px;
      margin-bottom: 0.72rem;
      margin-right: 2px;
      margin-right: 24px;
      .imgbox {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .pink {
        width: 1.28rem;
        height: 1.28rem;
        margin-bottom: 0.2rem;
        object-fit: scale-down;
      }

      .text {
        font-size: 14px;
        color: #666666;
        word-break: break-all;
        text-align: center;
      }
    }
    li:nth-child(5n+5){
      margin-right: 0;
    }
  }
}

.img5Box {
  background-color: rgba(115, 86, 197, 1);
  padding: 0.76rem;

  .title {
    font-size: 38px;
    color: #fff;
    margin-bottom: 0.48rem;
    font-weight: bold;
  }

  .navbtnBox {
    .el-button {
      padding: 0.13rem 0.34rem;
      background-color: #fff;
      color: rgba(0, 0, 0, 1);
      font-size: 18px;
      font-weight: bold;
    }

    .el-button:hover {
      color: #7356c5;
    }
  }
}
.listcode{
        flex-direction: column !important;
      }
      .codepng{
        margin-top: 40px;
        font-size: 12px;
      }
</style>
