import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import '../public/reset.css'
import '../element-variables.scss'
import '../src/style/flex.css';
import '../src/style/base.css';
Vue.config.productionTip = false
Vue.use(ElementUI);     //全局注入element
new Vue({
  router,
  store,
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc()
    window.addEventListener('resize', setRemPc) //浏览器窗口大小改变时调用rem换算方法
  },
  render: h => h(App),
}).$mount('#app')

//rem计算
function setRemPc() {
  var whdef = 100 / 1920 // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth // 当前窗口的宽度
  var rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
}