<template>
  <div id="app">
    <router-view/>
    <footer :style="{'background': $route.name=='About'||$route.name=='Clause'?'#F5F4F5':''}">
       <div class="flex1 divbox">
          <ul  class="navul align-center flex1">
            <li class="navulLi" v-for="(v, i) in footerList" :key="i" @click="gopage(v)" v-if="$route.name!=v.name">{{ v.title }}</li>
          </ul>
          <div class="text">Copyright © saanw.com. All Rights Reserved. 备案号: <a href="https://beian.miit.gov.cn" class="link">陕ICP备19018753号-2</a></div>
        </div>
        <div class="divboxr flex">
          <div class="item item1">
              <img src="@/assets/qr2.jpg" alt="" class="img1">
              <span>关注微信公众号</span>
          </div>
          <div class="item">
            <img src="@/assets/qrcode.png" alt="" class="img1">
              <span>商屋APP下载</span>
          </div>
        </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data(){
    return {
       footerList: [
       {title:'首页',name:'Home'},
       {title:'联系我们',name:'Contact'},
       {title:'关于商屋',name:'About'},
       {title:'服务条款',name:'Clause'},
       ],
    }
  },
  created(){
    this.href()
  },
  updated() {
window.scroll(0, 0);
},
mounted() {
    window.onresize = () => {
        this.href()
      };
  },
   methods: {
    href(){
      if (this._isMobile()) {
        if (window.location.hostname === 'localhost') {
          window.location.href='https://m.saanw.com/'
          return
        }
        window.location.href='https://m.saanw.com/'
      } else {
  
      }
    },
    _isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
    },
    gopage(v){
      if(v.name=='Home'){
        this.$router.push({name:'Home'})
      }else if(v.name=='Contact'){
        this.$router.push({name:'Contact'})
      }else if(v.name=='About'){
        this.$router.push({name:'About'})
      }else if(v.name=='Clause'){
        this.$router.push({name:'Clause'})
      }
    },
   
   }
}
</script>
<style lang="scss">
@import './common/font-size.css';
@font-face {
  font-family: 'Microsoft Yahei';
	src: local("Hiragino Sans GB");
}
@font-face {
  font-family: 'Microsoft Yahei UI';
	src: local("Hiragino Sans GB");
}
img{
  image-rendering: -webkit-optimize-contrast;
}
body{
  min-width: 1200px;
  font-family: PingFang;
}
.divbox{
  flex-direction: column;
}
img{
  object-fit: cover;
}
footer{
  display: flex;
  justify-content: space-between;
  padding: 0.43rem 2.4rem;
  .text{
    color: rgba(0, 0, 0, 1);
font-size: 14px;

  }
  .divboxr{
    .item1{
      margin-right: 32px;
    }
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    .img1{
      width: 110px;
      height: 110px;
      margin-bottom: 8px;
    }
    span{
      font-size: 14px;
      color: #666;

    }
  }
}
.navul {

    .navulLi {
      font-size: 16px;
      margin-right: 0.4rem;
      margin-bottom: 32px;
      cursor: pointer;
    }

    .navulLi:hover {
      color: #7356C5;
    }
  }
}
a{
  text-decoration: none !important;
}
.link{
  color: #7356C5;
  cursor: pointer;
}

</style>
